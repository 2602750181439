import React, { useRef, useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Button, Alert, Modal, Spinner } from 'react-bootstrap';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebaseConfig';

function LoginPage() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const newPasswordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [updatingPassword, setUpdatingPassword] = useState(false); // loading state for modal
  const [userUID, setUserUID] = useState('');
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError('');
      setLoading(true);
      const user = await login(emailRef.current.value, passwordRef.current.value);
      const tokenResult = await user.getIdTokenResult();
      setUserUID(user.uid)
      if (user.mustChangePassword) {
        setShowPasswordModal(true); // Show modal if password change is required
      } else {
        navigate('/');
      }
    } catch {
      setError('Failed to log in');
    }

    setLoading(false);
  }

  async function handlePasswordUpdate() {
    setUpdatingPassword(true);
    try {
      const updateUser = httpsCallable(functions, 'updateUser');
      await updateUser({
        uid: userUID,
        password: newPasswordRef.current.value,
        mustChangePassword: false,
      });

      setShowPasswordModal(false);
      navigate('/');
    } catch (error) {
      setError('Failed to update password: ' + error.message);
    }
    setUpdatingPassword(false);
  }

  return (
    <div className="d-flex align-items-center justify-content-center">
      <Container className="d-flex align-items-center justify-content-center mt-5">
        <div className="w-100" style={{ maxWidth: '500px' }}>
          <h2 className="text-center mb-4">Sign In</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>
            <Button type="submit" disabled={loading}>Login</Button>
          </Form>

          {/* Modal for password change */}
          <Modal show={showPasswordModal} onHide={() => setShowPasswordModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Update Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId="newPassword">
                <Form.Label>New Password</Form.Label>
                <Form.Control type="password" ref={newPasswordRef} required />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowPasswordModal(false)}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handlePasswordUpdate} disabled={updatingPassword}>
                {updatingPassword ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{' '}
                    Updating...
                  </>
                ) : 'Update Password'}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Container>
    </div>
  );
}

export default LoginPage;
