import React, { useEffect, useState } from 'react';
import { db } from '../../firebaseConfig'; // Ensure your Firebase config is correctly set up
import { collection, query, getDocs } from 'firebase/firestore';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import HolderCard from './HolderCard'; // Import the HolderCard component

// Register Chart.js components
Chart.register(ArcElement, Tooltip, Legend);

// Custom plugin to display the number of sales orders without PO in the center of the Doughnut
const centerTextPlugin = {
  id: 'centerText',
  beforeDraw: function (chart) {
    const ctx = chart.ctx;
    const width = chart.width;
    const height = chart.height;
    const withoutPONbr = chart.data.datasets[0].data[1]; // Number of sales orders without PO
    const withPONbr = chart.data.datasets[0].data[0];
    const totalSO = parseInt(withoutPONbr) + parseInt(withPONbr)
    console.log("Chart Data: ", chart.data)

    ctx.restore();
    const fontSize = (height / 114).toFixed(2); // Dynamically scale the font size
    ctx.font = `${fontSize}em sans-serif`;
    ctx.textBaseline = 'middle';

    const text = totalSO.toString(); // Display the number of sales orders without PO
    const textX = Math.round((width - ctx.measureText(text).width) / 2);
    const textY = height / 2;

    ctx.fillText(text, textX, textY);
    ctx.save();
  }
};

const SalesOrdersPONbrChart = () => {
  const [salesOrdersData, setSalesOrdersData] = useState({
    withPONbr: 0,
    withoutPONbr: 0,
  });

  useEffect(() => {
    // Fetch sales orders and categorize based on strPONbr
    const fetchData = async () => {
      const salesOrdersQuery = query(collection(db, 'salesOrders'));
      const salesOrdersSnapshot = await getDocs(salesOrdersQuery);

      let withPONbr = 0;
      let withoutPONbr = 0;

      salesOrdersSnapshot.forEach((doc) => {
        const data = doc.data();
        const hasPONbr = data.strPONbr && data.strPONbr.trim() !== ''; // Check if PONbr exists and is not an empty string
        if (hasPONbr) {
          withPONbr++;
        } else {
          withoutPONbr++;
        }
      });

      setSalesOrdersData({ withPONbr, withoutPONbr });
    };

    fetchData();
  }, []);

  // Data for the chart with green for sales orders with PO and red for without PO
  const chartData = {
    labels: ['With PO Number', 'Without PO Number'],
    datasets: [
      {
        label: 'Sales Orders',
        data: [salesOrdersData.withPONbr, salesOrdersData.withoutPONbr],
        backgroundColor: ['green', 'red'], // Green for with PO and Red for without PO
      },
    ],
  };

  // Hide the legend and configure the chart
  const chartOptions = {
    plugins: {
      legend: {
        display: false, // This hides the legend
      },
    },
  };

  return (
    <HolderCard title="Sales Orders by PO Number">
      <Doughnut data={chartData} options={chartOptions} plugins={[centerTextPlugin]} />
    </HolderCard>
  );
};

export default SalesOrdersPONbrChart;
