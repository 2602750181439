import React, { useEffect, useState } from 'react';
import { db } from '../../firebaseConfig';
import { collection, query, getDocs } from 'firebase/firestore';
import { Line, Bar } from 'react-chartjs-2';
import { format, startOfWeek, addWeeks, addDays, addMonths, subDays, subMonths, subWeeks, startOfMonth, isAfter } from 'date-fns';
import { Chart, LineElement, BarElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';

// Register Chart.js components
Chart.register(LineElement, BarElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

const SalesOrdersByDate = () => {
  const [salesOrdersData, setSalesOrdersData] = useState([]);
  const [selectedView, setSelectedView] = useState('weekly'); // Default view is week
  const [selectedRange, setSelectedRange] = useState(6); // Default is last 6 weeks
  const [chartType, setChartType] = useState('line'); // Default chart type is line

  useEffect(() => {
    const fetchSalesOrders = async () => {
      const q = query(collection(db, 'salesOrders'));
      const querySnapshot = await getDocs(q);
      const orders = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setSalesOrdersData(orders);
    };

    fetchSalesOrders();
  }, []);

  // Reset range when selectedView changes
  useEffect(() => {
    if (selectedView === 'daily') {
      setSelectedRange(7); // Default to last 7 days
    } else if (selectedView === 'weekly') {
      setSelectedRange(6); // Default to last 6 weeks
    } else if (selectedView === 'monthly') {
      setSelectedRange(3); // Default to last 3 months
    }
  }, [selectedView]);

  // Parse strSalesOrdNbr to get the date
  const parseOrderDate = (strSalesOrdNbr) => {
    const year = `20${strSalesOrdNbr.slice(0, 2)}`; // Get year from the first two digits
    const month = strSalesOrdNbr.slice(2, 4); // Get month
    const day = strSalesOrdNbr.slice(4, 6); // Get day
    return new Date(`${year}-${month}-${day}`);
  };

  // Get the date filter based on selected view (days, weeks, months)
  const getStartDate = () => {
    const today = new Date();
    if (selectedView === 'daily') {
      return subDays(today, selectedRange);
    } else if (selectedView === 'weekly') {
      return subWeeks(today, selectedRange);
    } else if (selectedView === 'monthly') {
      return subMonths(today, selectedRange);
    }
  };

  // Filter orders based on selected view (day, week, month)
  const getFilteredOrders = () => {
    const startDate = getStartDate();
    return salesOrdersData.filter((order) => {
      const orderDate = parseOrderDate(order.strSalesOrdNbr);
      return isAfter(orderDate, startDate);
    });
  };

  // Group orders by the selected view (day, week, month)
  const groupOrdersByView = () => {
    const filteredOrders = getFilteredOrders();
    const groupedData = {};

    filteredOrders.forEach((order) => {
      const orderDate = parseOrderDate(order.strSalesOrdNbr);
      let periodStart;

      if (selectedView === 'daily') {
        periodStart = format(orderDate, 'yyyy-MM-dd');
      } else if (selectedView === 'weekly') {
        periodStart = format(startOfWeek(orderDate, { weekStartsOn: 1 }), 'yyyy-MM-dd'); // Start the week on Monday
      } else if (selectedView === 'monthly') {
        periodStart = format(startOfMonth(orderDate), 'yyyy-MM');
      }

      if (!groupedData[periodStart]) {
        groupedData[periodStart] = 0;
      }
      groupedData[periodStart]++;
    });

    return groupedData;
  };

  // Generate chart data
  const generateChartData = () => {
    const groupedData = groupOrdersByView();

    const labels = [];
    const data = [];
    let startDate = getStartDate();

    while (isAfter(new Date(), startDate)) {
      let periodLabel;
      if (selectedView === 'daily') {
        periodLabel = format(startDate, 'yyyy-MM-dd');
        startDate = addDays(startDate, 1);
      } else if (selectedView === 'weekly') {
        periodLabel = format(startOfWeek(startDate, { weekStartsOn: 1 }), 'yyyy-MM-dd'); // Start the week on Monday
        startDate = addWeeks(startDate, 1);
      } else if (selectedView === 'monthly') {
        periodLabel = format(startOfMonth(startDate), 'yyyy-MM');
        startDate = addMonths(startDate, 1);
      }

      labels.push(periodLabel);
      data.push(groupedData[periodLabel] || 0); // Use 0 if there are no orders for that period
    }

    return {
      labels,
      datasets: [
        {
          label: `Sales Orders (${selectedView.charAt(0).toUpperCase() + selectedView.slice(1)} View)`,
          data,
          fill: false,
          borderColor: '#36A2EB',
          backgroundColor: '#36A2EB',
          tension: 0.1,
        },
      ],
    };
  };

  const renderChart = () => {
    const chartData = generateChartData();

    if (chartType === 'line') {
      return <Line data={chartData} />;
    } else if (chartType === 'bar') {
      return <Bar data={chartData} />;
    }
  };

  // Dynamic range options based on the selected view
  const getRangeOptions = () => {
    if (selectedView === 'daily') {
      return (
        <>
          <option value={7}>7 Days</option>
          <option value={30}>30 Days</option>
          <option value={90}>90 Days</option>
        </>
      );
    } else if (selectedView === 'weekly') {
      return (
        <>
          <option value={6}>6 Weeks</option>
          <option value={13}>13 Weeks</option>
          <option value={26}>26 Weeks</option>
          <option value={39}>39 Weeks</option>
          <option value={52}>52 Weeks</option>
        </>
      );
    } else if (selectedView === 'monthly') {
      return (
        <>
          <option value={3}>3 Months</option>
          <option value={6}>6 Months</option>
          <option value={12}>12 Months</option>
          <option value={24}>24 Months</option>
        </>
      );
    }
  };

  return (
    <div style={{ maxWidth: '800px', margin: '0 auto' }}>
      <h2>Sales Orders Created by {selectedView.charAt(0).toUpperCase() + selectedView.slice(1)}</h2>
      <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
        <select value={selectedView} onChange={(e) => setSelectedView(e.target.value)}>
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
        </select>
        <select value={selectedRange} onChange={(e) => setSelectedRange(Number(e.target.value))}>
          {getRangeOptions()}
        </select>
        <select value={chartType} onChange={(e) => setChartType(e.target.value)}>
          <option value="line">Line Chart</option>
          <option value="bar">Bar Chart</option>
        </select>
      </div>
      {renderChart()}
    </div>
  );
};

export default SalesOrdersByDate;
