import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, OverlayTrigger, Tooltip, FormControl, InputGroup, Form, Pagination, Row, Col } from 'react-bootstrap';
import ContactForm from './ContactForm';
import { Search, PlusCircle, PlusCircleFill, InfoCircle, Trash, Wrench, ArrowDownUp } from "react-bootstrap-icons";
import { useFirestore } from '../contexts/FirestoreContext';
import QRCode from 'qrcode.react';

function ContactList({ customerNumber, onContactAdded, upfitterNumber }) {
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [contactToEdit, setContactToEdit] = useState(null);
  const [contactToDelete, setContactToDelete] = useState(null);
  const [contactToShow, setContactToShow] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const { getData, deleteData, updateData } = useFirestore();
  const [reload, setReload] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const contactData = await getData('customerContacts');
        let filteredContacts
        if (!customerNumber || customerNumber === ""){
          filteredContacts = contactData.filter(contact => contact.strUpfitterNbr === upfitterNumber)
        } else {
          filteredContacts = contactData.filter(contact => contact.strCustNbr === customerNumber)
        }
        setContacts(filteredContacts);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [getData, reload]);


  useEffect(() => {
    const filterContacts = (query) => {
      const lowercasedQuery = query.toLowerCase();
      const filtered = contacts.filter(contact => {
        return (
          contact.nContactId.toString().toLowerCase().includes(lowercasedQuery) ||
          contact.strContactName.toLowerCase().includes(lowercasedQuery) ||
          contact.nContactType.toLowerCase().includes(lowercasedQuery) ||
          contact.nContactEmail.toLowerCase().includes(lowercasedQuery) ||
          contact.nContactPhone.toLowerCase().includes(lowercasedQuery)
        );
      });

      setFilteredContacts(filtered);
      setTotalRows(filtered.length);
      setCurrentPage(1); // Reset to the first page when filtering changes
    };

    filterContacts(searchQuery);
  }, [contacts, searchQuery]);


  const generateVCardString = (contact) => {
    const vCardString = 
      `BEGIN:VCARD
      VERSION:3.0
      FN:${contact.strContactName || ''}
      TEL;TYPE=WORK,VOICE:${contact.nContactPhone || ''}
      EMAIL:${contact.nContactEmail || ''}
      ADR;TYPE=WORK:;;${contact.strContactAddressLine1 || ''} ${contact.strContactAddressLine2 || ''};${contact.strContactCity || ''};${contact.strContactState || ''};${contact.strContactZip || ''}
      END:VCARD`;
    return vCardString
  };




  const handleContactAdded = async () => {
    const contactData = await getData('customerContacts', 'strCustNbr', '==', customerNumber);
    setContacts(contactData);
    setReload(reload + 1);
    setShowAddModal(false); // Close the modal after adding the contact
  };

  const getFormattedAddress = (contact) => {
    const addressLine1 = contact.strContactAddressLine1 || '';
    const addressLine2 = contact.strContactAddressLine2 ? `, ${contact.strContactAddressLine2}` : '';
    const city = contact.strContactCity ? `, ${contact.strContactCity}` : '';
    const state = contact.strContactState ? `, ${contact.strContactState}` : '';
    const zip = contact.strContactZip ? `, ${contact.strContactZip}` : '';

    return `${addressLine1}${addressLine2}${city}${state}${zip}`.trim();
  };


  const handleContactUpdated = async (updatedContact) => {
    if (!updatedContact || !updatedContact.id) {
      console.error('Updated contact or contact ID is missing');
      return;
    }
    await updateData('customerContacts', updatedContact.id, updatedContact);
    const updatedContacts = contacts.map(contact => (contact.id === updatedContact.id ? updatedContact : contact));
    setContacts(updatedContacts);
  };

  const handleContactDeleted = async (contact) => {
    await deleteData('customerContacts', contact.id);
    const updatedContacts = contacts.filter(c => c.id !== contact.id);
    setContacts(updatedContacts);
    setReload(reload + 1);
    setShowDeleteModal(false);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSortClick = (column) => {
    const sortedContacts = [...filteredContacts].sort((a, b) => {
      const fieldA = a[column].toString().toLowerCase();
      const fieldB = b[column].toString().toLowerCase();
      if (fieldA < fieldB) return -1;
      if (fieldA > fieldB) return 1;
      return 0;
    });
    setFilteredContacts(sortedContacts);
  };

  const handleContactRowClick = (contact) => {
    if (selectedContact && selectedContact.id === contact.id) {
      setSelectedContact(null); // Deselect if the same contact is clicked again
    } else {
      setSelectedContact(contact);
    }
  };

  // Pagination logic
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredContacts.slice(indexOfFirstRow, indexOfLastRow);

  const renderPaginationItems = () => {
    const pageNumbers = [];
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    const maxPagesToShow = 5;
    let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }

    pageNumbers.push(
      <Pagination.First key="first" onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
    );
    pageNumbers.push(
      <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
    );

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      );
    }

    pageNumbers.push(
      <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
    );
    pageNumbers.push(
      <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
    );

    return pageNumbers;
  };

  return (
    <>
      <InputGroup className="mb-2">
        <InputGroup.Text id="search-addon"><Search /></InputGroup.Text>
        <FormControl
          placeholder="Search contacts..."
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </InputGroup>
      <Table hover>
        <thead>
          <tr>
            <th>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">Add Contact</Tooltip>}
              >
                <Button
                  onClick={() => setShowAddModal(true)}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  className="icon-hover me-3"
                  variant="link"
                >
                  {isHovered ? <PlusCircleFill size={20} /> : <PlusCircle size={20} />}
                </Button>
              </OverlayTrigger>
            </th>
            <th>
              <div className="d-flex justify-content-between align-items-center">
                <span>Contact ID</span>
                <Button variant='white' onClick={() => handleSortClick('nContactId')}>
                  <ArrowDownUp />
                </Button>
              </div>
            </th>
            <th>
              <div className="d-flex justify-content-between align-items-center">
                <span>Name</span>
                <Button variant='white' onClick={() => handleSortClick('strContactName')}>
                  <ArrowDownUp />
                </Button>
              </div>
            </th>
            <th>
              <div className="d-flex justify-content-between align-items-center">
                <span>Email</span>
                <Button variant='white' onClick={() => handleSortClick('nContactEmail')}>
                  <ArrowDownUp />
                </Button>
              </div>
            </th>
            <th>
              <div className="d-flex justify-content-between align-items-center">
                <span>Phone</span>
                <Button variant='white' onClick={() => handleSortClick('nContactPhone')}>
                  <ArrowDownUp />
                </Button>
              </div>
            </th>
            <th>
              <div className="d-flex justify-content-between align-items-center">
                <span>Address</span>
              </div>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map(contact => (
            <React.Fragment key={contact.id}>
              <tr onClick={() => handleContactRowClick(contact)}>
                <td></td>
                <td>{contact.nContactId}</td>
                <td>{contact.strContactName}</td>
                <td>{contact.nContactEmail}</td>
                <td>{contact.nContactPhone}</td>
                <td>{getFormattedAddress(contact)}</td>
                <td>
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Detail</Tooltip>}>
                    <Button
                      onClick={() => { setContactToShow(contact); setShowDetailModal(true); }}
                      variant="white"
                      style={{ padding: '2px 4px' }}
                    >
                      <InfoCircle color="blue" />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Edit</Tooltip>}>
                    <Button
                      onClick={() => { setContactToEdit(contact); setShowEditModal(true); }}
                      variant="white"
                      style={{ padding: '2px 4px' }}
                    >
                      <Wrench color="black" />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Delete</Tooltip>}>
                    <Button
                      onClick={() => { setContactToDelete(contact); setShowDeleteModal(true); }}
                      variant="white"
                      style={{ padding: '2px 4px' }}
                    >
                      <Trash color="red" />
                    </Button>
                  </OverlayTrigger>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-between align-items-center my-3 mb-5" style={{ flexWrap: 'wrap' }}>
        <div>
          Showing {indexOfFirstRow + 1} to {Math.min(indexOfLastRow, totalRows)} of {totalRows} rows
        </div>
        <div className="d-flex align-items-center">
          <span className="me-2">Rows per page:</span>
          <Form.Select value={rowsPerPage} onChange={handleRowsPerPageChange} style={{ width: 'auto' }}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </Form.Select>
        </div>
        <Pagination className="mb-0">
          {renderPaginationItems()}
        </Pagination>
      </div>

      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ContactForm
            customerNumber={customerNumber}
            onContactAdded={handleContactAdded}
            onClose={() => setShowAddModal(false)}
          />
        </Modal.Body>
      </Modal>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Edit Contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ContactForm
            contact={contactToEdit}
            customerNumber={customerNumber}
            upfitterNumber={upfitterNumber}
            onContactUpdated={(updatedContact) => {
              handleContactUpdated(updatedContact);
              setReload(reload + 1);
              setShowEditModal(false);
            }}
            onClose={() => setShowEditModal(false)}
          />
        </Modal.Body>
      </Modal>

      <Modal show={showDetailModal} onHide={() => setShowDetailModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Contact Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {contactToShow && (
            <div style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
              <Row>
                <Col><strong>Contact ID:</strong></Col>
                <Col>{contactToShow.nContactId}</Col>
              </Row>
              <Row>
                <Col><strong>Name:</strong></Col>
                <Col>{contactToShow.strContactName}</Col>
              </Row>
              <Row>
                <Col><strong>Email:</strong></Col>
                <Col>{contactToShow.nContactEmail}</Col>
              </Row>
              <Row>
                <Col><strong>Phone:</strong></Col>
                <Col>{contactToShow.nContactPhone}</Col>
              </Row>
              <Row>
                <Col><strong>Address:</strong></Col>
                <Col>{getFormattedAddress(contactToShow)}</Col>
              </Row>
              <Row>
                <Col><strong>Notes:</strong></Col>
                <Col>{contactToShow.strContactNotes}</Col>
              </Row>
            </div>
          )}
        </Modal.Body>
      </Modal>


      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this contact?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <Button variant="danger" onClick={() => handleContactDeleted(contactToDelete)}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ContactList;
