import React, { useEffect, useState } from 'react';
import { db } from '../../firebaseConfig'; // Ensure your Firebase config is correctly set up
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import HolderCard from './HolderCard'; // Assuming the HolderCard is already created

// Register Chart.js components
Chart.register(ArcElement, Tooltip, Legend);

// Custom plugin to display the total in the center of the Doughnut
const centerTextPlugin = {
  id: 'centerText',
  beforeDraw: function (chart) {
    const ctx = chart.ctx;
    const width = chart.width;
    const height = chart.height;
    const data = chart.data.datasets[0].data;

    const total = data.reduce((sum, value) => sum + value, 0); // Calculate total

    ctx.restore();
    const fontSize = (height / 114).toFixed(2); // Dynamically scale the font size
    ctx.font = `${fontSize}em sans-serif`;
    ctx.textBaseline = 'middle';

    const text = total; // Total number of vehicles in stock
    const textX = Math.round((width - ctx.measureText(text).width) / 2);
    const textY = height / 2;

    ctx.fillText(text, textX, textY);
    ctx.save();
  }
};

const VehiclesInStockChart = () => {
  const [vehiclesInStock, setVehiclesInStock] = useState([]);
  const [modelCodesMap, setModelCodesMap] = useState({});

  useEffect(() => {
    const fetchVehiclesInStock = async () => {
      // Query vehicles where strSalesOrdNbr is 'Stock'
      const vehiclesQuery = query(collection(db, 'vehicles'), where('strSalesOrdNbr', '==', 'Stock'));
      const vehiclesSnapshot = await getDocs(vehiclesQuery);
      const vehicles = vehiclesSnapshot.docs.map(doc => doc.data());

      // Set vehicles in stock to state
      setVehiclesInStock(vehicles);
    };

    const fetchModelCodes = async () => {
      const modelCodesSnapshot = await getDocs(collection(db, 'modelCodes'));
      const modelCodes = modelCodesSnapshot.docs.reduce((acc, doc) => {
        const data = doc.data();
        acc[data.strModel] = data.strModelName; // Map strModel to strModelName
        return acc;
      }, {});

      setModelCodesMap(modelCodes); // Store the model codes map in state
    };

    fetchVehiclesInStock();
    fetchModelCodes();
  }, []);

  // Group vehicles by strModel and convert strModel to strModelName
  const vehicleModelCount = vehiclesInStock.reduce((acc, vehicle) => {
    const modelName = modelCodesMap[vehicle.strModel] || vehicle.strModel; // Fallback to strModel if not found
    const makeModelName = vehicle.strYR + " " + vehicle.strMake + " " + modelName

    if (acc[makeModelName]) {
      acc[makeModelName]++;
    } else {
      acc[makeModelName] = 1;
    }
    return acc;
  }, {});

  // Prepare chart data
  const chartData = {
    labels: Object.keys(vehicleModelCount), // List of model names
    datasets: [
      {
        label: 'Vehicles in Stock',
        data: Object.values(vehicleModelCount), // Number of vehicles per model
        backgroundColor: Object.keys(vehicleModelCount).map(() => `hsl(${Math.random() * 360}, 100%, 35%)`), // Generate random colors
      },
    ],
  };

  // Chart options, can hide the legend or add other customizations
  const chartOptions = {
    plugins: {
      legend: {
        display: false, // Show the legend
      },
    },
  };

  return (
    <HolderCard title="Vehicles in Stock by Make and Model">
      <Doughnut data={chartData} options={chartOptions} plugins={[centerTextPlugin]} /> {/* Apply the custom plugin */}
    </HolderCard>
  );
};

export default VehiclesInStockChart;
