import React, { useEffect, useState } from 'react';
import { db } from '../../firebaseConfig'; // Ensure your Firebase config is correctly set up
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import HolderCard from './HolderCard'; // Assuming the HolderCard is already created

// Register Chart.js components
Chart.register(ArcElement, Tooltip, Legend);

// Map hex codes to human-readable color names
const colorMapping = {
  '#FFA500': 'orange',
  '#FF0000': 'red',
  '#008000': 'green',
  '#ffa500': 'orange',
  '#ff0000': 'red',
  '#008000': 'green',
  'orange': 'orange',
  'red': 'red',
  'green': 'green',
};

// Normalize the color by converting hex to human-readable name if it exists
const normalizeColor = (color) => {
  return colorMapping[color?.toLowerCase()] || color; // If no mapping found, return the original color
};

// Custom plugin to display the total number of vehicles in the center of the doughnut
const centerTextPlugin = {
  id: 'centerText',
  beforeDraw: function (chart) {
    const ctx = chart.ctx;
    const width = chart.width;
    const height = chart.height;
    const data = chart.data.datasets[0].data;

    const total = data.reduce((sum, value) => sum + value, 0); // Calculate total

    ctx.restore();
    const fontSize = (height / 114).toFixed(2); // Dynamically scale the font size
    ctx.font = `${fontSize}em sans-serif`;
    ctx.textBaseline = 'middle';

    const text = total; // Total number of vehicles
    const textX = Math.round((width - ctx.measureText(text).width) / 2);
    const textY = height / 2;

    ctx.fillText(text, textX, textY);
    ctx.save();
  }
};

const VehicleStatusChart = () => {
  const [vehiclesStatusData, setVehiclesStatusData] = useState([]);
  const [eventCodesData, setEventCodesData] = useState([]); // Store event codes

  useEffect(() => {
    const fetchData = async () => {
      // Fetch active sales orders
      const salesOrdersQuery = query(
        collection(db, 'salesOrders'),
        where('strSalesOrdStatus', '==', 'Active')
      );
      const salesOrdersSnapshot = await getDocs(salesOrdersQuery);
      const activeSalesOrders = salesOrdersSnapshot.docs.map(doc => doc.data().strSalesOrdNbr);

      // Fetch vehicles that are part of active sales orders
      const vehiclesQuery = query(collection(db, 'vehicles'));
      const vehiclesSnapshot = await getDocs(vehiclesQuery);
      const vehicles = vehiclesSnapshot.docs
        .map(doc => doc.data())
        .filter(vehicle => activeSalesOrders.includes(vehicle.strSalesOrdNbr)); // Filter vehicles with active sales orders

      // Fetch event codes for status colors
      const eventCodesQuery = query(collection(db, 'eventCodes'));
      const eventCodesSnapshot = await getDocs(eventCodesQuery);
      const eventCodes = eventCodesSnapshot.docs.map(doc => doc.data());

      // Set event codes and vehicles data to state
      setEventCodesData(eventCodes);
      setVehiclesStatusData(vehicles);
    };

    fetchData();
  }, []);

  // Helper function to find color by strManStat, strFactStatus, or strUpfitterStatus
  const getStatusColor = (vehicle) => {
    let statusColor;

    // Check if the vehicle has an upfitter request and if strUpfitterStatus is valid
    if (vehicle.upfitterReq && vehicle.strUpfitterStatus && vehicle.strUpfitterStatus.trim() !== '') {
      // Find a matching event code by strUpfitterStatus
      const eventCode = eventCodesData.find(code =>
        code.strFactStatus === vehicle.strUpfitterStatus || code.strManStat === vehicle.strUpfitterStatus
      );
      statusColor = eventCode ? eventCode.strStatusColor : 'black'; // Fallback to black if not found
    } else {
      // Use strManStat or strFactStatus if no upfitter status is found
      const eventCode = eventCodesData.find(code =>
        code.strManStat === vehicle.strManStat || code.strFactStatus === vehicle.strFactStatus
      );
      statusColor = eventCode ? eventCode.strStatusColor : 'black'; // Fallback to black if not found
    }
    return normalizeColor(statusColor); // Normalize the color to group similar colors
  };

  // Group vehicles by color (retrieved from eventCodes and normalized)
  const vehicleColorCount = vehiclesStatusData.reduce((acc, vehicle) => {
    const statusColor = getStatusColor(vehicle); // Get color based on status

    if (acc[statusColor]) {
      acc[statusColor]++;
    } else {
      acc[statusColor] = 1;
    }
    return acc;
  }, {});

  // Prepare chart data
  const chartData = {
    labels: Object.keys(vehicleColorCount),
    datasets: [
      {
        label: 'Vehicle Status by Color',
        data: Object.values(vehicleColorCount),
        backgroundColor: Object.keys(vehicleColorCount), // Use the color as background color
      },
    ],
  };

  // Chart options, can hide the legend or add other customizations
  const chartOptions = {
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
    },
  };

  return (
    <HolderCard title="Vehicle Status by Status Color">
      <Doughnut data={chartData} options={chartOptions} plugins={[centerTextPlugin]} /> {/* Apply the custom plugin */}
    </HolderCard>
  );
};

export default VehicleStatusChart;
