// src/components/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

function PrivateRoute({ children, roles }) {
  const { currentUser } = useAuth();
  const [userRole, setUserRole] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (currentUser) {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const idTokenResult = await user.getIdTokenResult();
          setUserRole(idTokenResult.claims.role);
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
  }, [currentUser]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!currentUser) {
    return <Navigate to="/login" />; // Redirect to login if not authenticated
  }

  if (roles && !roles.includes(userRole)) {
    return <Navigate to="/" />; // Redirect if user role is not allowed
  }

  return children;
}

export default PrivateRoute;
