// src/pages/ReportingDashboardPage.js
import React from 'react';
import Dashboard from '../components/Dashboard';
import Reports from '../components/Reports';

function ReportingDashboardPage() {
  return (
    <div>
      <Dashboard />
    </div>
  );
}

export default ReportingDashboardPage;