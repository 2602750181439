import React, { useContext, useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, addDoc, getDocs, updateDoc, doc, getDoc, deleteDoc, query, where, onSnapshot, serverTimestamp } from 'firebase/firestore';  // Import serverTimestamp directly

const FirestoreContext = React.createContext();

export function useFirestore() {
  return useContext(FirestoreContext);
}

export function FirestoreProvider({ children }) {
  const [loading, setLoading] = useState(true);

  const addData = async (collectionName, data) => {
    const docRef = await addDoc(collection(db, collectionName), data);
    return docRef.id;
  };

  const getData = async (collectionName) => {
    const querySnapshot = await getDocs(collection(db, collectionName));
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  };

  const queryData = async (collectionName, field, operator, value) => {
    const q = query(collection(db, collectionName), where(field, operator, value));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  };

  const getDocument = async (collectionName, docId) => {
    const docRef = doc(db, collectionName, docId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      throw new Error(`No document found for id: ${docId}`);
    }
  };

  const updateData = async (collectionName, docId, newData) => {
    if (!collectionName || !docId) {
      throw new Error('Collection name or Document ID is undefined');
    }

    const docRef = doc(db, collectionName, docId);

    // Get the current data
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      throw new Error(`No document found for id: ${docId}`);
    }

    const currentData = docSnap.data();

    // Track if any changes are detected
    let isDataDifferent = false;

    // Compare each field in newData with the currentData
    for (const [key, value] of Object.entries(newData)) {
      if (currentData[key] !== value) {
        isDataDifferent = true;
        break; // No need to check further if a change is found
      }
    }

    // If there is a difference, update the document and add the lastUpdated field
    if (isDataDifferent) {
      await updateDoc(docRef, {
        ...newData,
        lastUpdated: serverTimestamp(), // Use serverTimestamp from the module
      });
    }
  };

  const deleteData = async (collectionName, docId) => {
    const docRef = doc(db, collectionName, docId);
    await deleteDoc(docRef);
  };

  const onSnapshotData = (collectionName, callback) => {
    return onSnapshot(collection(db, collectionName), (snapshot) => {
      const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      callback(data);
    });
  };

  const onSnapshotQueryData = (collectionName, field, operator, value, callback) => {
    const q = query(collection(db, collectionName), where(field, operator, value));
    return onSnapshot(q, (snapshot) => {
      const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      callback(data);
    });
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  const value = {
    addData,
    getData,
    queryData,
    getDocument,
    updateData,
    deleteData,
    onSnapshotData,
    onSnapshotQueryData
  };

  return (
    <FirestoreContext.Provider value={value}>
      {!loading && children}
    </FirestoreContext.Provider>
  );
}
