import React, { useState, useEffect } from 'react';
import { Table, Button, FormControl, InputGroup, Modal, OverlayTrigger, Tooltip, Pagination, Form } from 'react-bootstrap';
import { PlusCircle, Trash, CheckCircle, XCircle, Wrench, Brush, Search } from 'react-bootstrap-icons';
import { useFirestore } from '../contexts/FirestoreContext';
import { SketchPicker } from 'react-color';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

function AdminTable({ collectionName, fields }) {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [newItem, setNewItem] = useState({});
  const [editItem, setEditItem] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showEditColorPicker, setShowEditColorPicker] = useState(null);
  const { getData, updateData, addData, deleteData } = useFirestore();
  const [newItemColor, setNewItemColor] = useState('white');
  const [searchQuery, setSearchQuery] = useState('');
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [role, setRole] = useState(null);

  const presetColors = ["red", "orange", "green", "yellow"];

  const fieldDisplayNames = {
    strFactory: "Factory",
    strModel: "Model Code",
    strModelName: "Model Name",
    strColor: "Color Code",
    strColorName: "Color Name",
    strManStat: "Status Code",
    strFactStatus: "Status Description",
    strFactStatusCust: "Customer Status Description",
    strStatusColor: "Status Color",
  };

  console.log("CollectionName Before: ", collectionName)

  useEffect(() => {
    if (currentUser) {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const idTokenResult = await user.getIdTokenResult();
          setRole(idTokenResult.claims.role);
        }
      });
    }
  }, [currentUser]);
  
  useEffect(() => {
    const fetchData = async () => {
      console.log("CollectionName: ", collectionName)
      if(collectionName === 'none') {
        console.log("ROLE: ", role)
        if (role === 'BDC'){
          navigate(`/admin?section=users`);
        }
      } else {
        const data = await getData(collectionName);
        setItems(data);
        setFilteredItems(data);
        setTotalRows(data.length);
      }
    };
    fetchData();
  }, [getData, collectionName, role]);

  const handleInputChange = (e, field, item, setItem) => {
    setItem({ ...item, [field]: e.target.value });
  };

  const handleColorChange = (color, field, item, setItem, addColor) => {
    if (addColor) {
      setNewItemColor(color);
    }
    setItem({ ...item, [field]: color.hex });
  };

  const handleAddNewItem = async () => {
    if (!newItem.strFactory) {
      alert(`Factory is a required field.`);
      return;
    }
    const requiredFields = fields.filter(field => ['strColor', 'strModel', 'strManStat'].includes(field));
    const missingFields = requiredFields.filter(field => !newItem[field]);

    if (missingFields.length > 0) {
      alert(`${fieldDisplayNames[missingFields[0]]} is a required field.`);
      return;
    }

    const isDuplicate = items.some(item =>
      requiredFields.some(field => item[field] === newItem[field])
    );

    if (isDuplicate) {
      alert("One or more of the required fields must be unique and not exist.");
      return;
    }

    await addData(collectionName, newItem);
    setNewItemColor("white");
    setNewItem({});
    setShowColorPicker(false);
    const data = await getData(collectionName);
    setItems(data);
    setFilteredItems(data);
    setTotalRows(data.length);
  };

  const handleUpdateItem = async () => {
    await updateData(collectionName, editItem.id, editItem);
    setEditItem(null);
    setShowEditColorPicker(null);
    const data = await getData(collectionName);
    setItems(data);
    setFilteredItems(data);
    setTotalRows(data.length);
  };

  const handleDeleteItem = async () => {
    await deleteData(collectionName, itemToDelete.id);
    setItemToDelete(null);
    setShowDeleteModal(false);
    const data = await getData(collectionName);
    setItems(data);
    setFilteredItems(data);
    setTotalRows(data.length);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    const lowercasedQuery = query.toLowerCase();
    const filtered = items.filter(item =>
      fields.some(field =>
        item[field]?.toString().toLowerCase().includes(lowercasedQuery)
      )
    );
    setFilteredItems(filtered);
    setTotalRows(filtered.length);
    setCurrentPage(1); // Reset to the first page when filtering changes
  };

  const handleSort = (field) => {
    const order = (sortField === field && sortOrder === 'asc') ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(order);

    const sorted = [...filteredItems].sort((a, b) => {
      if (a[field] < b[field]) return order === 'asc' ? -1 : 1;
      if (a[field] > b[field]) return order === 'asc' ? 1 : -1;
      return 0;
    });
    setFilteredItems(sorted);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredItems.slice(indexOfFirstRow, indexOfLastRow);

  const renderPaginationItems = () => {
    const pageNumbers = [];
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    const maxPagesToShow = 5;
    let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }

    pageNumbers.push(
      <Pagination.First key="first" onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
    );
    pageNumbers.push(
      <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
    );

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      );
    }

    pageNumbers.push(
      <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
    );
    pageNumbers.push(
      <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
    );

    return pageNumbers;
  };

  const renderRow = (item) => {
    return (
      <tr key={item.id}>
        {fields.map(field => (
          <td key={field}>
            {editItem && editItem.id === item.id ? (
              field === 'strStatusColor' ? (
                <>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: '24px',
                        height: '24px',
                        backgroundColor: editItem[field],
                        cursor: 'pointer'
                      }}
                      onClick={() => setShowEditColorPicker(item.id)}
                    ></div>
                    {showEditColorPicker === item.id && (
                      <div style={{ position: 'absolute', zIndex: 2 }}>
                        <SketchPicker
                          color={editItem[field]}
                          presetColors={presetColors}
                          onChange={(color) => handleColorChange(color, field, editItem, setEditItem, false)}
                        />
                        <Button onClick={() => setShowEditColorPicker(null)} style={{ marginTop: '10px' }}>
                          Close
                        </Button>
                      </div>
                    )}
                  </div>
                </>
              ) : field === 'strFactory' ? (
                <Form.Select
                  value={editItem[field]}
                  onChange={(e) => handleInputChange(e, field, editItem, setEditItem)}
                >
                  <option value="FORD">FORD</option>
                  <option value="GM">GM</option>
                  {collectionName === "eventCodes" && (
                    <option value="UPFITTER">UPFITTER</option>
                  )}
                </Form.Select>
              ) : (
                <FormControl
                  value={editItem[field]}
                  onChange={(e) => handleInputChange(e, field, editItem, setEditItem)}
                  readOnly={field === 'strColor' || field === 'strModel' || field === 'strManStat'}
                />
              )
            ) : (
              field === 'strStatusColor' ? (
                <div style={{ width: '24px', height: '24px', backgroundColor: item[field] }}></div>
              ) : (
                item[field]
              )
            )}
          </td>
        ))}
        <td>
          {editItem && editItem.id === item.id ? (
            <div className="d-flex align-items-center">
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-save">Save</Tooltip>}>
                <Button variant="link" onClick={handleUpdateItem}><CheckCircle /></Button>
              </OverlayTrigger>
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-cancel">Cancel</Tooltip>}>
                <Button variant="link" onClick={() => { setEditItem(null); setShowEditColorPicker(null); }}><XCircle /></Button>
              </OverlayTrigger>
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-edit">Edit</Tooltip>}>
                <Button variant="link" onClick={() => setEditItem(item)}><Wrench /></Button>
              </OverlayTrigger>
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-delete">Delete</Tooltip>}>
                <Button variant="link" onClick={() => { setItemToDelete(item); setShowDeleteModal(true); }}><Trash /></Button>
              </OverlayTrigger>
            </div>
          )}
        </td>
      </tr>
    );
  };

  return (
    <>
      <InputGroup className="mb-2">
        <InputGroup.Text><Search /></InputGroup.Text>
        <FormControl
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </InputGroup>

      <InputGroup className="mb-2">
        {fields.map(field => (
          field === 'strStatusColor' ? (
            <div style={{ display: 'flex', alignItems: 'center' }} key={field}>
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-pick-color">Pick Color</Tooltip>}>
                <div
                  style={{
                    width: '38px',
                    height: '38px',
                    backgroundColor: newItem[field] || '#fff',
                    cursor: 'pointer',
                    border: '1px solid #ccc'
                  }}
                  onClick={() => setShowColorPicker(!showColorPicker)}
                >
                  <Brush size={25} style={{ margin: '6px' }} />
                </div>
              </OverlayTrigger>
              {showColorPicker && (
                <div style={{ position: 'absolute', zIndex: 2 }}>
                  <SketchPicker
                    color={newItem[field] || newItemColor}
                    presetColors={presetColors}
                    onChange={(color) => handleColorChange(color, field, newItem, setNewItem, true)}
                  />
                  <Button onClick={() => setShowColorPicker(null)} style={{ marginTop: '10px' }}>
                    Close
                  </Button>
                </div>
              )}
            </div>
          ) : field === 'strFactory' ? (
            <Form.Select
              key={field}
                value={newItem[field] || ''}
              onChange={(e) => handleInputChange(e, field, newItem, setNewItem)}
            >
              <option value="" disabled>Select Factory</option>
              <option value="FORD">FORD</option>
              <option value="GM">GM</option>
              {collectionName === "eventCodes" && (
                <option value="UPFITTER">UPFITTER</option>
              )}
            </Form.Select>
          ) : (
            <FormControl
              key={field}
              placeholder={fieldDisplayNames[field] || field}
              value={newItem[field] || ''}
              onChange={(e) => handleInputChange(e, field, newItem, setNewItem)}
              required={['strColor', 'strModel', 'strManStat'].includes(field)}
            />
          )
        ))}
        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-add">Add New Item</Tooltip>}>
          <InputGroup.Text onClick={handleAddNewItem} style={{ cursor: 'pointer' }}>
            <PlusCircle />
          </InputGroup.Text>
        </OverlayTrigger>
      </InputGroup>

      <Table hover>
        <thead>
          <tr>
            {fields.map(field => (
              <th key={field} onClick={() => handleSort(field)} style={{ cursor: 'pointer' }}>
                {fieldDisplayNames[field] || field} {sortField === field && (sortOrder === 'asc' ? '↑' : '↓')}
              </th>
            ))}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map(renderRow)}
        </tbody>
      </Table>

      <div className="d-flex justify-content-between align-items-center my-3" style={{ flexWrap: 'wrap' }}>
        <div>
          Showing {indexOfFirstRow + 1} to {Math.min(indexOfLastRow, totalRows)} of {totalRows} rows
        </div>
        <div className="d-flex align-items-center">
          <span className="me-2">Rows per page:</span>
          <Form.Select value={rowsPerPage} onChange={handleRowsPerPageChange} style={{ width: 'auto' }}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </Form.Select>
        </div>
        <Pagination className="mb-0">
          {renderPaginationItems()}
        </Pagination>
      </div>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this item?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <Button variant="danger" onClick={handleDeleteItem}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdminTable;
