import React, { useRef, useState, useEffect } from 'react';
import { useFirestore } from '../contexts/FirestoreContext';
import { Button, Form, Row, Col, FloatingLabel } from 'react-bootstrap';

function ContactForm({ onClose, customerNumber, upfitterNumber, onContactAdded, contact, onContactUpdated }) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [upfitters, setUpfitters] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedUpfitter, setSelectedUpfitter] = useState('');
  const { addData, updateData, getData } = useFirestore();

  const contactIdRef = useRef();
  const contactNameRef = useRef();
  const contactEmailRef = useRef();
  const contactPhoneRef = useRef();
  const contactAddressLine1Ref = useRef();
  const contactAddressLine2Ref = useRef();
  const contactCityRef = useRef();
  const contactStateRef = useRef();
  const contactZipRef = useRef();
  const contactNotesRef = useRef();

  useEffect(() => {
    const fetchCustomers = async () => {
      const customerData = await getData('customers');
      const upfitterData = await getData('Upfitters');
      const contactsData = await getData('customerContacts');

      setCustomers(customerData);
      setUpfitters(upfitterData);

      if (contact) {
        setSelectedCustomer(contact.strCustNbr);
        setSelectedUpfitter(contact.strUpfitterNbr);
      } else {
        setSelectedCustomer(customerNumber);
        setSelectedUpfitter(upfitterNumber);
        generateNewContactId(contactsData); // Generate new ContactId for a new contact
      }
    };

    fetchCustomers();
  }, [getData, contact, customerNumber]);

  // Generate new ContactId by finding the highest existing one
  const generateNewContactId = (contacts) => {
    if (!contacts || contacts.length === 0) {
      contactIdRef.current.value = "2000"; // Start with "2000" if no contacts exist
    } else {
      const maxId = contacts.reduce((max, contact) => {
        const contactIdNumber = parseInt(contact.nContactId, 10); // Convert contactId string to number
        return contactIdNumber > max ? contactIdNumber : max;
      }, 2000); // Start at 2000 if no higher ID exists

      contactIdRef.current.value = (maxId + 1).toString(); // Increment and convert back to string
    }
  };

  useEffect(() => {
    if (contact) {
      if (contactIdRef.current) contactIdRef.current.value = contact.nContactId || '';
      if (contactNameRef.current) contactNameRef.current.value = contact.strContactName || '';
      if (contactEmailRef.current) contactEmailRef.current.value = contact.nContactEmail || '';
      if (contactPhoneRef.current) contactPhoneRef.current.value = contact.nContactPhone || '';

      if (contactAddressLine1Ref.current) contactAddressLine1Ref.current.value = contact.strContactAddressLine1 || '';
      if (contactAddressLine2Ref.current) contactAddressLine2Ref.current.value = contact.strContactAddressLine2 || '';
      if (contactCityRef.current) contactCityRef.current.value = contact.strContactCity || '';
      if (contactStateRef.current) contactStateRef.current.value = contact.strContactState || '';
      if (contactZipRef.current) contactZipRef.current.value = contact.strContactZip || '';

      if (contactNotesRef.current) contactNotesRef.current.value = contact.strContactNotes || '';
    }
  }, [contact]);

  const handleCustomerChange = (e) => {
    setSelectedCustomer(e.target.value);
  };

  const handleUpfitterChange = (e) => {
    setSelectedUpfitter(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    if (!selectedCustomer && !selectedUpfitter) {
      setError('You must select either a Customer or a Upfitter.');
      setLoading(false);
      return;
    }

    const newContact = {
      nContactId: contactIdRef.current.value,
      strCustNbr: selectedCustomer || '',
      strUpfitterNbr: selectedUpfitter || '',
      strContactName: contactNameRef.current.value,
      nContactEmail: contactEmailRef.current.value,
      nContactPhone: contactPhoneRef.current.value,

      strContactAddressLine1: contactAddressLine1Ref.current.value,
      strContactAddressLine2: contactAddressLine2Ref.current.value,
      strContactCity: contactCityRef.current.value,
      strContactState: contactStateRef.current.value,
      strContactZip: contactZipRef.current.value,

      strContactNotes: contactNotesRef.current.value,
    };

    try {
      if (contact) {
        await updateData('customerContacts', contact.id, newContact);
        if (onContactUpdated) {
          onContactUpdated(newContact);
        }
      } else {
        await addData('customerContacts', newContact);
        if (onContactAdded) {
          onContactAdded(newContact);
        }
      }
      onClose();
    } catch (err) {
      setError('Failed to save contact');
    }

    setLoading(false);
  };

  return (
    <div className="mt-4" style={{ width: '100%', margin: '0 auto' }}>
      {error && <div className="alert alert-danger">{error}</div>}
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="contactId">
              <FloatingLabel controlId="contactId" label="Contact ID">
                <Form.Control type="number" ref={contactIdRef} readOnly />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="contactName">
              <FloatingLabel controlId="contactName" label="Contact Name">
                <Form.Control type="text" ref={contactNameRef} />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="contactEmail">
              <FloatingLabel controlId="contactEmail" label="Contact Email">
                <Form.Control type="email" ref={contactEmailRef} />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="contactPhone">
              <FloatingLabel controlId="contactPhone" label="Contact Phone">
                <Form.Control type="text" ref={contactPhoneRef} />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="customerSelect">
              <FloatingLabel controlId="customerSelect" label="Customer">
                <Form.Select value={selectedCustomer} onChange={handleCustomerChange}>
                  <option value="">None</option>
                  {customers.map(customer => (
                    <option key={customer.id} value={customer.strCustNbr}>
                      {customer.strCustName} ({customer.strCustNbr})
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="upfitterSelect">
              <FloatingLabel controlId="upfitterSelect" label="Upfitter">
                <Form.Select value={selectedUpfitter} onChange={handleUpfitterChange}>
                  <option value="">None</option>
                  {upfitters.map(upfitter => (
                    <option key={upfitter.id} value={upfitter.strUpfitterNbr}>
                      {upfitter.strUpfitterName} ({upfitter.strUpfitterNbr})
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        {/* Address fields */}
        <Row>
          <Col xs={12}>
            <Form.Group className="mb-3" controlId="contactAddressLine1">
              <FloatingLabel controlId="contactAddressLine1" label="Address Line 1">
                <Form.Control type="text" ref={contactAddressLine1Ref} />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Group className="mb-3" controlId="contactAddressLine2">
              <FloatingLabel controlId="contactAddressLine2" label="Address Line 2">
                <Form.Control type="text" ref={contactAddressLine2Ref} />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3" controlId="contactCity">
              <FloatingLabel controlId="contactCity" label="City">
                <Form.Control type="text" ref={contactCityRef} />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3" controlId="contactState">
              <FloatingLabel controlId="contactState" label="State">
                <Form.Control type="text" ref={contactStateRef} />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3" controlId="contactZip">
              <FloatingLabel controlId="contactZip" label="ZIP Code">
                <Form.Control type="text" ref={contactZipRef} />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Group className="mb-3" controlId="contactNotes">
              <FloatingLabel controlId="contactNotes" label="Notes">
                <Form.Control as="textarea" ref={contactNotesRef} style={{ height: '100px' }} />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Button variant="primary" type="submit" disabled={loading}>
          {contact ? 'Update Contact' : 'Add Contact'}
        </Button>
      </Form>
    </div>
  );
}

export default ContactForm;
