import React from 'react';
import { Container } from 'react-bootstrap';
import VehicleList from '../components/VehicleList';

function VehiclePage() {
  return (
    <VehicleList addButtonActive={true} allVehicles={true}/>
  );
}

export default VehiclePage;

