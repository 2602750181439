import React, { useRef, useState, useEffect } from 'react';
import { useFirestore } from '../contexts/FirestoreContext';
import { Form, Button, Col, Row, FloatingLabel } from 'react-bootstrap';

function SalesIdForm({ salesId, onSalesIdUpdated, onSalesIdAdded, onModalClose, registerCleanup }) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { addData, updateData } = useFirestore();
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const salesIdNbrRef = useRef();
  const salesIdNameRef = useRef();

  useEffect(() => {
    if (salesId) {
      if (salesIdNbrRef.current) salesIdNbrRef.current.value = salesId.strSalesIdNbr;
      if (salesIdNameRef.current) salesIdNameRef.current.value = salesId.strSalesIdName;
    }
  }, [salesId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const newSalesId = {
        strSalesIdNbr: salesIdNbrRef.current.value,
        strSalesIdName: salesIdNameRef.current.value,
      };

      if (salesId) {
        await updateData('salesIds', salesId.id, newSalesId);
        onSalesIdUpdated(newSalesId);
      } else {
        await addData('salesIds', newSalesId);
        if (onSalesIdAdded) {
          onSalesIdAdded(newSalesId);
        }
      }

    } catch (err) {
      setError('Failed to save sales Id');
      console.error("Error updating/adding sales Id:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-4" style={{ width: '100%', margin: '0 auto' }}>
      {error && <div className="alert alert-danger">{error}</div>}
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="salesIdNbr">
              <FloatingLabel controlId="salesIdNbr" label="Sales Id Number">
                <Form.Control type="text" ref={salesIdNbrRef} disabled={!!salesId} />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="salesIdName">
              <FloatingLabel controlId="salesIdName" label="Sales Id Name">
                <Form.Control type="text" ref={salesIdNameRef} />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Button variant="primary" type="submit" disabled={loading || buttonsDisabled} className="mt-3">
          {salesId ? 'Update Sales Id' : 'Add Sales Id'}
        </Button>
      </Form>
    </div>
  );
}

export default SalesIdForm;
