import React, { useEffect, useState, useRef } from 'react';
import { Table, Modal, OverlayTrigger, Tooltip, Button, Pagination, Form, InputGroup, FormControl, Col, Row } from 'react-bootstrap';
import UpfitterForm from './UpfitterForm';
import { useFirestore } from '../contexts/FirestoreContext';
import { ChevronDown, ChevronUp, ArrowDownUp, InfoCircle, Trash, Wrench, PlusCircle, PlusCircleFill, Search } from "react-bootstrap-icons";
import ContactList from './ContactList';


function UpfitterList() {
  const [upfitters, setUpfitters] = useState([]);
  const [selectedUpfitter, setselectedUpfitter] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [upfitterToDelete, setUpfitterToDelete] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [upfitterToEdit, setUpfitterToEdit] = useState(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [upfitterToShow, setUpfitterToShow] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [localUpfitters, setLocalUpfitters] = useState([]);
  const { getData, deleteData, updateData,onSnapshotData } = useFirestore();
  const cleanupRef = useRef(null);
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      // Listener for Upfitters
      const upfitterUnsubscribe = onSnapshotData('Upfitters', (upfitterData) => {
        setUpfitters(upfitterData);
      });

      // Listener for Contacts
      const contactUnsubscribe = onSnapshotData('customerContacts', (contactData) => {
        setContacts(contactData);
      });

      // Clean up listeners on component unmount
      return () => {
        upfitterUnsubscribe();
        contactUnsubscribe();
      };
    };

    fetchData();
  }, [onSnapshotData]); // Dependency to ensure `onSnapshotData` changes reinitialize listeners


  

  useEffect(() => {
    filterUpfitters(searchQuery);
  }, [upfitters, searchQuery]);

  const filterUpfitters = (query) => {
    const lowercasedQuery = query.toLowerCase();
    const filteredUpfitters = upfitters.filter(upfitter => {
      return (
        upfitter.strUpfitterNbr?.toLowerCase().includes(lowercasedQuery) ||
        upfitter.strUpfitterName?.toLowerCase().includes(lowercasedQuery) ||
        upfitter.strUpfitterNotes?.toLowerCase().includes(lowercasedQuery) 
      );
    });
    setLocalUpfitters(filteredUpfitters);
    setTotalRows(filteredUpfitters.length);
    setCurrentPage(1); // Reset to the first page when filtering changes
  };

  const handleUpfitterAdded = async () => {
    const upfitterData = await getData('upfitters');
    setUpfitters(upfitterData);
    setShowAddModal(false); // Close the modal after adding the upfitter
  };

  const getContactListForUpfitter = (upfitterNbr) => {
    const upfitterContacts = contacts.filter(contact => contact.strUpfitterNbr === upfitterNbr)
    return upfitterContacts;
  };

  const handleContactAdded = async () => {
    const contactData = await getData('customerContacts');
    setContacts(contactData);
  };

  const handleUpfitterUpdated = async (updatedUpfitter) => {
    if (!updatedUpfitter || !updatedUpfitter.id) {
      console.error('Updated Upfitter or Upfitter ID is missing');
      return;
    }
    await updateData('upfitters', updatedUpfitter.id, updatedUpfitter);
    const updatedUpfitters = upfitters.map(upfitter => (upfitter.id === updatedUpfitter.id ? updatedUpfitter : upfitter));
    setUpfitters(updatedUpfitters);
  };

  // Combine address fields into a single formatted string
  const getFormattedAddress = (upfitter) => {
    const addressLine1 = upfitter.strAddressLine1 || '';
    const addressLine2 = upfitter.strAddressLine2 ? `, ${upfitter.strAddressLine2}` : '';
    const city = upfitter.strCity ? `, ${upfitter.strCity}` : '';
    const state = upfitter.strState ? `, ${upfitter.strState}` : '';
    const zip = upfitter.strZip ? `, ${upfitter.strZip}` : '';
    return `${addressLine1}${addressLine2}${city}${state}${zip}`.trim();
  };

  const handleUpfitterDeleted = async (upfitter) => {
    await deleteData('Upfitters', upfitter.id);
    const updatedUpfitters = upfitters.filter(v => v.id !== upfitter.id);
    setUpfitters(updatedUpfitters);
    setShowDeleteModal(false);
  };

  const handleCloseModal = () => {
    if (cleanupRef.current) {
      cleanupRef.current(); // Call the cleanup function if it exists
    }
    setShowAddModal(false);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSortClick = (column) => {
    let sortedUpfitters;
    if (column === 'strUpfitterName') {
      sortedUpfitters = [...localUpfitters].sort((a, b) => {
        const nameA = a.strUpfitterName.toLowerCase();
        const nameB = b.strUpfitterName.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    } else {
      sortedUpfitters = [...localUpfitters].sort((a, b) => {
        const fieldA = a[column].toLowerCase();
        const fieldB = b[column].toLowerCase();

        if (fieldA < fieldB) return -1;
        if (fieldA > fieldB) return 1;
        return 0;
      });
    }
    setLocalUpfitters(sortedUpfitters);
  };

  // Pagination logic
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = localUpfitters.slice(indexOfFirstRow, indexOfLastRow);

  const renderPaginationItems = () => {
    const pageNumbers = [];
    const totalPages = Math.ceil(localUpfitters.length / rowsPerPage);
    const maxPagesToShow = 5;
    let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }

    pageNumbers.push(
      <Pagination.First key="first" onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
    );
    pageNumbers.push(
      <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
    );

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      );
    }

    pageNumbers.push(
      <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
    );
    pageNumbers.push(
      <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
    );

    return pageNumbers;
  };

  return (
    <>
      <InputGroup className="mb-3">
        <InputGroup.Text id="search-addon"><Search /></InputGroup.Text>
        <FormControl
          placeholder="Search Upfitters"
          aria-label="Search Upfitters"
          aria-describedby="search-addon"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </InputGroup>
      <Table hover>
        <thead>
          <tr>
            <th>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">Add Upfitter</Tooltip>}
              >
                <Button
                  onClick={() => setShowAddModal(true)}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  className="icon-hover me-3"
                  variant="link"
                >
                  {isHovered ? <PlusCircleFill size={20} /> : <PlusCircle size={20} />}
                </Button>
              </OverlayTrigger>
            </th>
            <th>
              <div className="d-flex justify-content-between align-items-center">
                <span>Upfitter Number</span>
                <Button variant='white' onClick={() => handleSortClick('strUpfitterNbr')}>
                  <ArrowDownUp />
                </Button>
              </div>
            </th>
            <th>
              <div className="d-flex justify-content-between align-items-center">
                <span>Upfitter Name</span>
                <Button variant='white' onClick={() => handleSortClick('strUpfitterName')}>
                  <ArrowDownUp />
                </Button>
              </div>
            </th>
            <th>
              <div className="d-flex justify-content-between align-items-center">
                <span>Upfitter Notes</span>
                <Button variant='white' onClick={() => handleSortClick('strUpfitterNotes')}>
                  <ArrowDownUp />
                </Button>
              </div>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map(upfitter => (
            <React.Fragment key={upfitter.strUpfitterNbr}>
              <tr onClick={() => setselectedUpfitter(upfitter)}>
                <td>{selectedUpfitter && selectedUpfitter.strUpfitterNbr === upfitter.strUpfitterNbr ? <ChevronUp /> : <ChevronDown />}</td>
                <td>{upfitter.strUpfitterNbr}</td>
                <td>{upfitter.strUpfitterName}</td>
                <td>{upfitter.strUpfitterNotes}</td>
                <td>
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Detail</Tooltip>}>
                    <Button
                      onClick={() => { setUpfitterToShow(upfitter); setShowDetailModal(true) }}
                      variant="white"
                      style={{ padding: '2px 4px' }}
                    >
                      <InfoCircle color="blue" />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Edit</Tooltip>}>
                    <Button
                      onClick={() => { setUpfitterToEdit(upfitter); setShowEditModal(true); }}
                      variant="white"
                      style={{ padding: '2px 4px' }}
                    >
                      <Wrench color="black" />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Delete</Tooltip>}>
                    <Button
                      onClick={() => { setUpfitterToDelete(upfitter); setShowDeleteModal(true); }}
                      variant="white"
                      style={{ padding: '2px 4px' }}
                    >
                      <Trash color="red" />
                    </Button>
                  </OverlayTrigger>
                </td>
              </tr>
              {selectedUpfitter && selectedUpfitter.strUpfitterNbr === upfitter.strUpfitterNbr && (
                <tr>
                  <td colSpan="6">
                    <ContactList
                      contacts={getContactListForUpfitter(upfitter.strUpfitterNbr)}
                      customerNumber=""
                      upfitterNumber={upfitter.strUpfitterNbr}
                      onContactAdded={handleContactAdded}
                    />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-between align-items-center my-3 mb-5" style={{ flexWrap: 'wrap' }}>
        <div>
          Showing {indexOfFirstRow + 1} to {Math.min(indexOfLastRow, totalRows)} of {totalRows} rows
        </div>
        <div className="d-flex align-items-center">
          <span className="me-2">Rows per page:</span>
          <Form.Select value={rowsPerPage} onChange={handleRowsPerPageChange} style={{ width: 'auto' }}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </Form.Select>
        </div>
        <Pagination className="mb-0">
          {renderPaginationItems()}
        </Pagination>
      </div>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this Upfitter?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <Button variant="danger" onClick={() => handleUpfitterDeleted(upfitterToDelete)}>Delete</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Edit Upfitter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpfitterForm
            upfitter={upfitterToEdit}
            onUpfitterUpdated={(updatedUpfitter) => {
              handleUpfitterUpdated(updatedUpfitter);
              setShowEditModal(false);
            }}
          />
        </Modal.Body>
      </Modal>

      <Modal show={showDetailModal} onHide={() => setShowDetailModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Upfitter Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {upfitterToShow && (
            <div style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
              <Row>
                <Col><strong>Upfitter Number:</strong></Col>
                <Col>{upfitterToShow.strUpfitterNbr}</Col>
              </Row>
              <Row>
                <Col><strong>Name:</strong></Col>
                <Col>{upfitterToShow.strUpfitterName}</Col>
              </Row>
              <Row>
                <Col><strong>Address:</strong></Col>
                <Col>{getFormattedAddress(upfitterToShow)}</Col>
              </Row>
              <Row>
                <Col><strong>Notes:</strong></Col>
                <Col>{upfitterToShow.strUpfitterNotes}</Col>
              </Row>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showAddModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Upfitter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpfitterForm
            onUpfitterAdded={handleUpfitterAdded}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UpfitterList;
