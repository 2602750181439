import React, { useEffect, useState } from 'react';
import { db } from '../../firebaseConfig'; // Ensure your Firebase config is correctly set up
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import HolderCard from './HolderCard'; // Import the HolderCard component

// Register Chart.js components
Chart.register(ArcElement, Tooltip, Legend);

// Custom plugin to display the total in the center of the Doughnut
const centerTextPlugin = {
  id: 'centerText',
  beforeDraw: function (chart) {
    const ctx = chart.ctx;
    const width = chart.width;
    const height = chart.height;
    const data = chart.data.datasets[0].data;

    const total = data.reduce((sum, value) => sum + value, 0); // Calculate total

    ctx.restore();
    const fontSize = (height / 114).toFixed(2); // Dynamically scale the font size
    ctx.font = `${fontSize}em sans-serif`;
    ctx.textBaseline = 'middle';

    const text = total; // Total number of active sales orders
    const textX = Math.round((width - ctx.measureText(text).width) / 2);
    const textY = height / 2;

    ctx.fillText(text, textX, textY);
    ctx.save();
  }
};

// Function to generate a unique color palette
const generateUniqueColors = (numColors) => {
  const colors = [];
  for (let i = 0; i < numColors; i++) {
    const color = `hsl(${Math.random() * 360}, 100%, 35%)`; // Generate random HSL color
    colors.push(color);
  }
  return colors;
};

const SalesOrdersChart = () => {
  const [salesOrdersData, setSalesOrdersData] = useState([]);
  const [customersData, setCustomersData] = useState({}); // Store customer data

  useEffect(() => {
    // Fetch sales orders and customer data
    const fetchData = async () => {
      // Fetch active sales orders
      const salesOrdersQuery = query(
        collection(db, 'salesOrders'),
        where('strSalesOrdStatus', '==', 'Active')
      );
      const salesOrdersSnapshot = await getDocs(salesOrdersQuery);
      const salesOrders = salesOrdersSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      // Fetch customers data
      const customersQuery = query(collection(db, 'customers'));
      const customersSnapshot = await getDocs(customersQuery);
      const customers = customersSnapshot.docs.reduce((acc, doc) => {
        const customerData = doc.data();
        acc[customerData.strCustNbr] = customerData.strCustName; // Map customer number to name
        return acc;
      }, {});

      // Set the state for both sales orders and customers
      setSalesOrdersData(salesOrders);
      setCustomersData(customers);
    };

    fetchData();
  }, []);

  // Process data for the chart
  const customerOrderCount = salesOrdersData.reduce((acc, order) => {
    const customerNumber = order.strCustNbr;
    const customerName = customersData[customerNumber] || customerNumber; // Use name if available, otherwise fallback to number
    if (acc[customerName]) {
      acc[customerName]++;
    } else {
      acc[customerName] = 1;
    }
    return acc;
  }, {});

  const chartData = {
    labels: Object.keys(customerOrderCount),
    datasets: [
      {
        label: 'Open Sales Orders',
        data: Object.values(customerOrderCount),
        backgroundColor: generateUniqueColors(Object.keys(customerOrderCount).length), // Generate unique colors based on the number of customers
      },
    ],
  };

  // Hide the legend and configure the chart
  const chartOptions = {
    plugins: {
      legend: {
        display: false, // This hides the legend
      },
    },
  };

  return (
    <HolderCard title="Open Sales Orders by Customer">
      <Doughnut data={chartData} options={chartOptions} plugins={[centerTextPlugin]} />
    </HolderCard>
  );
};

export default SalesOrdersChart;
