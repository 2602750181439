import React from 'react';
import SalesOrdersChart from './chartItems/SalesOrdersChart';
import VehiclesExpected from './chartItems/VehiclesExpected';
import SalesOrdersByWeek from './chartItems/SalesOrdersByWeek';
import VehicleStatusChart from './chartItems/VehicleStatusChart';
import VehiclesInStockChart from './chartItems/VehiclesInStockChart';
import SalesOrdersPONbrChart from './chartItems/SalesOrdersPONbrChart';

function Dashboard() {
  return (
    <div className="card">
      <div className="card-body" style={{ marginBottom: '20px' }}>
        {/* Flexbox container for SalesOrdersChart and VehiclesExpected */}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', marginLeft:'100px', marginRight:'100px'  }}>
          <div style={{ flex: '1' }}>
            <SalesOrdersChart />
          </div>
          <div style={{ flex: '1' }}>
            <VehicleStatusChart />
          </div>
          <div style={{ flex: '1' }}>
            <VehiclesInStockChart />
          </div>
          <div style={{ flex: '1' }}>
            <SalesOrdersPONbrChart />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', marginLeft: '100px', marginRight: '100px' }}>
          <div style={{ flex: '1' }}>
            <VehiclesExpected />
          </div>
        </div>
        {/* SalesOrdersByWeek component below */}
        <div>
          <SalesOrdersByWeek />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
