import React, { useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import SalesOrderPage from './pages/SalesOrderPage';
import VehiclePage from './pages/VehiclePage';
import AdminPage from './pages/AdminPage';
import CustomerPortalPage from './pages/CustomerPortalPage';
import ReportingDashboardPage from './pages/ReportingDashboardPage';
import LogoutPage from './pages/LogoutPage';
import Header from './components/Header';
import Footer from './components/Footer';
import PrivateRoute from './components/PrivateRoute';

function App() {
  const idleTimeout = 30 * 60 * 1000; // 15 minutes

  const IdleTimeoutHandler = () => {
    const navigate = useNavigate();

    const logoutOnIdle = useCallback(() => {
      navigate('/logout'); // Redirect to the logout page
    }, [navigate]);

    useEffect(() => {
      const resetTimeout = () => {
        clearTimeout(window.idleTimer);
        window.idleTimer = setTimeout(logoutOnIdle, idleTimeout);
      };

      // Event listeners for user activity
      window.addEventListener('mousemove', resetTimeout);
      window.addEventListener('keydown', resetTimeout);

      // Set initial timer
      resetTimeout();

      return () => {
        clearTimeout(window.idleTimer);
        window.removeEventListener('mousemove', resetTimeout);
        window.removeEventListener('keydown', resetTimeout);
      };
    }, [logoutOnIdle, idleTimeout]);

    return null; // This component doesn't render anything
  };

  return (
    <Router>
      <IdleTimeoutHandler />
      <Header />
      <div
        style={{
          flex: "1",
          marginTop: '10px',
          marginLeft: '15px',
          marginRight: '15px',
          marginBottom: '5px',
        }}
      >
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/salesorders" element={<PrivateRoute roles={['Admin', 'Sales Manager', 'Sales Rep']}><SalesOrderPage /></PrivateRoute>} />
          <Route path="/vehicles" element={<PrivateRoute roles={['Admin', 'PDI', 'Sales Manager']}><VehiclePage /></PrivateRoute>} />
          <Route path="/admin" element={<PrivateRoute roles={['Admin', 'Sales Manager', 'Sales Rep', 'BDC']}><AdminPage /></PrivateRoute>} />
          <Route path="/customer" element={<PrivateRoute roles={['Customer']}><CustomerPortalPage /></PrivateRoute>} />
          <Route path="/dashboard" element={<PrivateRoute roles={['Admin', 'Sales Manager']}><ReportingDashboardPage /></PrivateRoute>} />
          <Route path="/logout" element={<LogoutPage />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;


