// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyAufI7dZRqij-hDTmapvEvEq61gVIfhe1s",
  authDomain: "fleet-sales-system.firebaseapp.com",
  projectId: "fleet-sales-system",
  storageBucket: "fleet-sales-system.appspot.com",
  messagingSenderId: "1059779836166",
  appId: "1:1059779836166:web:25ee533ba51ae8c15c8687",
  measurementId: "G-HHJYYN33DB"
};

const app = initializeApp(firebaseConfig);
//const db = getFirestore(app, "fleet-sales-system-dev");
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);

export { auth, db, functions };
