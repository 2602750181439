import React, { useRef, useState, useEffect } from 'react';
import { useFirestore } from '../contexts/FirestoreContext';
import { Form, Button, Col, Row, FloatingLabel } from 'react-bootstrap';

function UpfitterForm({ upfitter, onUpfitterUpdated, onupfitterAdded }) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { addData, updateData, queryData } = useFirestore();
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const upfitterNbrRef = useRef();
  const upfitterNameRef = useRef();
  const upfitterNotesRef = useRef();
  const upfitterEmailRef = useRef();
  const upfitterPhoneRef = useRef();
 
  // New address field refs
  const addressLine1Ref = useRef();
  const addressLine2Ref = useRef();
  const cityRef = useRef();
  const stateRef = useRef();
  const zipRef = useRef();

  useEffect(() => {
    if (upfitter) {
      if (upfitterNbrRef.current) upfitterNbrRef.current.value = upfitter.strUpfitterNbr;
      if (upfitterNameRef.current) upfitterNameRef.current.value = upfitter.strUpfitterName;
      if (upfitterNotesRef.current) upfitterNotesRef.current.value = upfitter.strUpfitterNotes;
      if (upfitterEmailRef.current) upfitterEmailRef.current.value = upfitter.strUpfitterEmail;
      if (upfitterPhoneRef.current) upfitterPhoneRef.current.value = upfitter.strUpfitterPhone;

      // Populate address fields
      if (addressLine1Ref.current) addressLine1Ref.current.value = upfitter.strAddressLine1 || '';
      if (addressLine2Ref.current) addressLine2Ref.current.value = upfitter.strAddressLine2 || '';
      if (cityRef.current) cityRef.current.value = upfitter.strCity || '';
      if (stateRef.current) stateRef.current.value = upfitter.strState || '';
      if (zipRef.current) zipRef.current.value = upfitter.strZip || '';
    }
  }, [upfitter]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const upfitterNumber = upfitterNbrRef.current.value;

      // Check if the upfitter already exists based on the upfitter number
      const existingUpfitter = await queryData('Upfitters', 'strUpfitterNbr', '==', upfitterNumber);

      if (existingUpfitter.length > 0 && !upfitter) { // If an upfitter already exists and we're not updating
        setError(`Upfitter with number ${upfitterNumber} already exists.`);
        setLoading(false);
        return;
      }

      const newupfitter = {
        strUpfitterNbr: upfitterNbrRef.current.value,
        strUpfitterName: upfitterNameRef.current.value,
        strUpfitterNotes: upfitterNotesRef.current.value,
        strUpfitterEmail: upfitterEmailRef.current.value,
        strUpfitterPhone: upfitterPhoneRef.current.value,
        // Address fields
        strAddressLine1: addressLine1Ref.current.value,
        strAddressLine2: addressLine2Ref.current.value,
        strCity: cityRef.current.value,
        strState: stateRef.current.value,
        strZip: zipRef.current.value
      };

      if (upfitter) {
        await updateData('Upfitters', upfitter.id, newupfitter);
        onUpfitterUpdated(newupfitter);
      } else {
        await addData('Upfitters', newupfitter);
        if (onupfitterAdded) {
          onupfitterAdded(newupfitter);
        }
      }

    } catch (err) {
      setError('Failed to save upfitter');
      console.error("Error updating/adding upfitter:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-4" style={{ width: '100%', margin: '0 auto' }}>
      {error && <div className="alert alert-danger">{error}</div>}
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="upfitterNbr">
              <FloatingLabel controlId="upfitterNbr" label="Upfitter Number">
                <Form.Control type="text" ref={upfitterNbrRef} disabled={!!upfitter} />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="upfitterName">
              <FloatingLabel controlId="upfitterName" label="Upfitter Name">
                <Form.Control type="text" ref={upfitterNameRef} />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="upfitterEmail">
              <FloatingLabel controlId="upfitterEmail" label="Upfitter Email">
                <Form.Control type="email" ref={upfitterEmailRef} />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="upfitterPhone">
              <FloatingLabel controlId="upfitterPhone" label="Upfitter Phone">
                <Form.Control type="text" ref={upfitterPhoneRef} />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        {/* New address fields */}
        <Row>
          <Col xs={12}>
            <Form.Group className="mb-3" controlId="addressLine1">
              <FloatingLabel controlId="addressLine1" label="Address Line 1">
                <Form.Control type="text" ref={addressLine1Ref} />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Group className="mb-3" controlId="addressLine2">
              <FloatingLabel controlId="addressLine2" label="Address Line 2">
                <Form.Control type="text" ref={addressLine2Ref} />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3" controlId="city">
              <FloatingLabel controlId="city" label="City">
                <Form.Control type="text" ref={cityRef} />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3" controlId="state">
              <FloatingLabel controlId="state" label="State">
                <Form.Control type="text" ref={stateRef} />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3" controlId="zip">
              <FloatingLabel controlId="zip" label="ZIP Code">
                <Form.Control type="text" ref={zipRef} />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="upfitterNotes">
              <FloatingLabel controlId="upfitterNotes" label="Upfitter Notes">
                <Form.Control as="textarea" ref={upfitterNotesRef} style={{ height: '100px' }} />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Button variant="primary" type="submit" disabled={loading || buttonsDisabled} className="mt-3">
          {upfitter ? 'Update Upfitter' : 'Add Upfitter'}
        </Button>
      </Form>
    </div>
  );
}

export default UpfitterForm;
