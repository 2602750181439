// src/pages/LogoutPage.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { getAuth, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const LogoutPage = () => {
  const navigate = useNavigate();
  const [msalConfig, setMsalConfig] = useState(null);

  useEffect(() => {
    const fetchMsalConfig = async () => {
      try {
        const db = getFirestore();
        const configDoc = await getDoc(doc(db, 'dataCollection', 'teams'));
        if (configDoc.exists()) {
          const configData = configDoc.data();
          const config = {
            auth: {
              clientId: configData.clientId,
              authority: `https://login.microsoftonline.com/${configData.tenantId}`,
              redirectUri: window.location.origin,
            },
          };
          setMsalConfig(config);
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching MSAL configuration from Firestore:', error);
      }
    };

    fetchMsalConfig();
  }, []);

  useEffect(() => {
    const logout = async () => {
      const auth = getAuth();
      try {
        await signOut(auth);
        if (msalConfig) {
          const msalInstance = new PublicClientApplication(msalConfig);
          msalInstance.logoutPopup().then(() => {
            navigate('/login'); // Redirect to login page after logout
          }).catch((error) => {
            console.error('Azure AD logout error:', error);
          });
        }
      } catch (error) {
        console.error('Firebase logout error:', error);
      }
    };

    if (msalConfig) {
      logout();
      navigate('/')
    }
  }, [msalConfig, navigate]);

  return (
    <div>
      <h2>Logging out...</h2>
    </div>
  );
};

export default LogoutPage;
