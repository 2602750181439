import React from 'react';
import { Container } from 'react-bootstrap';
import SalesOrderList from '../components/SalesOrderList';

function SalesOrderPage() {
  return (
    <SalesOrderList 
      isAdmin={true}
    />
  );
}

export default SalesOrderPage;
