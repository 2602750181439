import React, { useEffect, useState } from 'react';
import { db } from '../../firebaseConfig'; // Firebase config
import { collection, query, getDocs } from 'firebase/firestore';
import { startOfWeek, endOfWeek, addDays } from 'date-fns';
import HolderCard from './HolderCard'; // Import the HolderCard component

const VehiclesExpected = () => {
  const [thisWeekCount, setThisWeekCount] = useState(0);
  const [nextWeekCount, setNextWeekCount] = useState(0);

  useEffect(() => {
    const fetchVehicles = async () => {
      const q = query(collection(db, 'vehicles')); // Query the 'vehicles' collection
      const querySnapshot = await getDocs(q);

      const vehicles = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      // Filter and count vehicles based on dtManETA (Firestore timestamp)
      const now = new Date();
      const startOfCurrentWeek = startOfWeek(now, { weekStartsOn: 1 }); // Start of this week (Monday)
      const endOfCurrentWeek = endOfWeek(now, { weekStartsOn: 1 }); // End of this week (Sunday)
      const startOfNextWeek = addDays(startOfCurrentWeek, 7); // Start of next week (7 days from start of this week)
      const endOfNextWeek = addDays(endOfCurrentWeek, 7); // End of next week (7 days from end of this week)

      // Filter for vehicles expected this week
      const thisWeekVehicles = vehicles.filter((vehicle) => {
        const dtManETA = vehicle.dtManETA?.toDate(); // Firestore timestamp to JS date
        return dtManETA >= startOfCurrentWeek && dtManETA <= endOfCurrentWeek;
      });

      // Filter for vehicles expected next week
      const nextWeekVehicles = vehicles.filter((vehicle) => {
        const dtManETA = vehicle.dtManETA?.toDate(); // Firestore timestamp to JS date
        return dtManETA >= startOfNextWeek && dtManETA <= endOfNextWeek;
      });

      // Set the counts for display
      setThisWeekCount(thisWeekVehicles.length);
      setNextWeekCount(nextWeekVehicles.length);
    };

    fetchVehicles();
  }, []);

  return (
    <HolderCard title="Expected ETA">
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', maxWidth: '300px', margin: '0 auto' }}>
        <div style={{ backgroundColor: '#36A2EB', color: '#fff', padding: '0px', textAlign: 'center', borderRadius: '10px' }}>
          <h3>This Week</h3>
          <p style={{ fontSize: '3em' }}>{thisWeekCount}</p>
        </div>
        <div style={{ backgroundColor: '#4BC0C0', color: '#fff', padding: '0px', textAlign: 'center', borderRadius: '10px' }}>
          <h3>Next Week</h3>
          <p style={{ fontSize: '3em' }}>{nextWeekCount}</p>
        </div>
      </div>
    </HolderCard>
  );
};

export default VehiclesExpected;


