import React, { useRef, useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Container, Form, Button, Alert, Modal, Spinner } from 'react-bootstrap';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebaseConfig';

function HomePage() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const newPasswordRef = useRef(); // Reference for new password input
  const { login, logout, currentUser } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [role, setRole] = useState(null);
  const [showPasswordModal, setShowPasswordModal] = useState(false); // State for modal visibility
  const [updatingPassword, setUpdatingPassword] = useState(false); // Loading state for password update

  useEffect(() => {
    if (currentUser) {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const idTokenResult = await user.getIdTokenResult();
          setRole(idTokenResult.claims.role);
        }
      });
    }
  }, [currentUser]);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError('');
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);

      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const idTokenResult = await user.getIdTokenResult(true); // Force refresh the token
        const role = idTokenResult.claims.role;

        if (idTokenResult.claims.mustChangePassword) {
          setShowPasswordModal(true); // Show modal if user must change password
        } else if (role === 'Customer') {
          navigate('/customer');
        } else if (role === 'Admin') {
          navigate('/salesorders');
        } else {
          navigate('/');
        }
      }
    } catch {
      setError('Failed to log in');
    }

    setLoading(false);
  }

  async function handlePasswordUpdate() {
    const auth = getAuth();
    const user = auth.currentUser;
    const idTokenResult = await user.getIdTokenResult(true); // Force refresh the token
    const role = idTokenResult.claims.role;
    setUpdatingPassword(true);
    try {
      const updateUser = httpsCallable(functions, 'updateUser');
      await updateUser({
        uid: getAuth().currentUser.uid,
        password: newPasswordRef.current.value,
        mustChangePassword: false, // Clear the mustChangePassword flag
        role: role,
      });

      setShowPasswordModal(false);
      if (role === 'Customer') {
        navigate('/customer');
      } else if (role === 'Admin') {
        navigate('/salesorders');
      } else {
        navigate('/');
      }
    } catch (error) {
      setError('Failed to update password: ' + error.message);
    }
    setUpdatingPassword(false);
  }

  async function handleModalClose() {
    setShowPasswordModal(false); // Close the modal first
    await logout(); // Log the user out
    navigate('/'); // Redirect to the login screen
  }

  return (
    <div>
      <Container className="text-center mt-3">
        <h1>Welcome to the Hardy Fleet Portal!</h1>
        <img
          src={require('../assets/full-logo.png')}
          alt="Full Logo"
        />
        <h6>(770) 445-6655</h6>
      </Container>
      {!currentUser && (
        <Container className="d-flex align-items-center justify-content-center mt-3">
          <div className="w-100" style={{ maxWidth: '500px' }}>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" ref={passwordRef} required />
              </Form.Group>
              <Button type="submit" disabled={loading}>Login</Button>
            </Form>
          </div>
        </Container>
      )}

      {/* Modal for password update */}
      <Modal show={showPasswordModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="newPassword">
            <Form.Label>New Password</Form.Label>
            <Form.Control type="password" ref={newPasswordRef} required />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handlePasswordUpdate} disabled={updatingPassword}>
            {updatingPassword ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{' '}
                Updating...
              </>
            ) : 'Update Password'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default HomePage;
