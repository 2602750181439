import React from 'react';

const HolderCard = ({ title, children, width = '300px', height = 'auto', margin = '5px', padding = '10px' }) => {
  return (
    <div style={{
      maxWidth: width,
      height: height,
      margin: margin,
      padding: padding,
      border: '1px solid #ddd',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      textAlign: 'center',
      backgroundColor: '#fff',
    }}>
      <h4>{title}</h4>
      {children}
    </div>
  );
};

export default HolderCard;
