import React, { useEffect, useState, useRef } from 'react';
import { Table, Modal, OverlayTrigger, Tooltip, Button, Pagination, Form, InputGroup, FormControl } from 'react-bootstrap';
import SalesIdForm from './SalesIdForm';
import { useFirestore } from '../contexts/FirestoreContext';
import { ChevronDown, ChevronUp, ArrowDownUp, InfoCircle, Trash, Wrench, PlusCircle, PlusCircleFill, Search } from "react-bootstrap-icons";

function SalesIdList({ search }) {
  const [salesIds, setSalesIds] = useState([]);
  const [selectedSalesId, setSelectedSalesId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [salesIdToDelete, setSalesIdToDelete] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [salesIdToEdit, setSalesIdToEdit] = useState(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [salesIdToShow, setSalesIdToShow] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState(search);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [localSalesIds, setLocalSalesIds] = useState([]);
  const { getData, deleteData, updateData } = useFirestore();
  const cleanupRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const salesIdData = await getData('salesIds');
        setSalesIds(salesIdData);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [getData]);

  useEffect(() => {
    filterSalesIds(searchQuery);
  }, [salesIds, searchQuery]);

  const filterSalesIds = (query) => {
    const lowercasedQuery = query.toLowerCase();
    const filteredSalesIds = salesIds.filter(Id => {
      return (
        Id.strSalesIdNbr?.toLowerCase().includes(lowercasedQuery) ||
        Id.strSalesIdName?.toLowerCase().includes(lowercasedQuery)
      );
    });
    setLocalSalesIds(filteredSalesIds);
    setTotalRows(filteredSalesIds.length);
    setCurrentPage(1);
  };

  const handleSalesIdRowClick = (salesId) => {
    if (selectedSalesId && selectedSalesId.strSalesIdNbr === salesId.strSalesIdNbr) {
      setSelectedSalesId(null);
    } else {
      setSelectedSalesId(salesId);
    }
  };

  const handleSalesIdAdded = async () => {
    const salesIdData = await getData('salesIds');
    setSalesIds(salesIdData);
    setShowAddModal(false);
  };

  const handleSalesIdUpdated = async (updatedSalesId) => {
    if (!updatedSalesId || !updatedSalesId.id) {
      console.error('Updated sales Id or sales Id ID is missing');
      return;
    }
    await updateData('salesIds', updatedSalesId.id, updatedSalesId);
    const updatedSalesIds = salesIds.map(Id => (Id.id === updatedSalesId.id ? updatedSalesId : Id));
    setSalesIds(updatedSalesIds);
  };

  const handleSalesIdDeleted = async (salesId) => {
    await deleteData('salesIds', salesId.id);
    const updatedSalesIds = salesIds.filter(Id => Id.id !== salesId.id);
    setSalesIds(updatedSalesIds);
    setShowDeleteModal(false);
  };

  const handleCloseModal = () => {
    if (cleanupRef.current) {
      cleanupRef.current();
    }
    setShowAddModal(false);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSortClick = (column) => {
    let direction = 'asc';

    if (sortColumn === column && sortDirection === 'asc') {
      direction = 'desc';
    } else if (sortColumn === column && sortDirection === 'desc') {
      direction = 'asc';
    }

    setSortColumn(column);
    setSortDirection(direction);

    const sortedSalesIds = [...localSalesIds].sort((a, b) => {
      let fieldA = a[column]?.toString().toLowerCase() || '';
      let fieldB = b[column]?.toString().toLowerCase() || '';

      if (direction === 'asc') {
        return fieldA.localeCompare(fieldB);
      } else {
        return fieldB.localeCompare(fieldA);
      }
    });

    setLocalSalesIds(sortedSalesIds);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = localSalesIds.slice(indexOfFirstRow, indexOfLastRow);

  const renderPaginationItems = () => {
    const pageNumbers = [];
    const totalPages = Math.ceil(localSalesIds.length / rowsPerPage);
    const maxPagesToShow = 5;
    let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }

    pageNumbers.push(
      <Pagination.First key="first" onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
    );
    pageNumbers.push(
      <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
    );

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      );
    }

    pageNumbers.push(
      <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
    );
    pageNumbers.push(
      <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
    );

    return pageNumbers;
  };

  return (
    <>
      <InputGroup className="mb-3">
        <InputGroup.Text id="search-addon"><Search /></InputGroup.Text>
        <FormControl
          placeholder="Search Sales Ids"
          aria-label="Search Sales Ids"
          aria-describedby="search-addon"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </InputGroup>
      <Table hover>
        <thead>
          <tr>
            <th>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">Add Sales Id</Tooltip>}
              >
                <Button
                  onClick={() => setShowAddModal(true)}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  className="icon-hover me-3"
                  variant="link"
                >
                  {isHovered ? <PlusCircleFill size={20} /> : <PlusCircle size={20} />}
                </Button>
              </OverlayTrigger>
            </th>
            <th>
              <div className="d-flex justify-content-between align-items-center">
                <span>Sales ID</span>
                <Button variant="white" onClick={() => handleSortClick('strSalesIdNbr')}>
                  <ArrowDownUp />
                </Button>
              </div>
            </th>
            <th>
              <div className="d-flex justify-content-between align-items-center">
                <span>Name</span>
                <Button variant="white" onClick={() => handleSortClick('strSalesIdName')}>
                  <ArrowDownUp />
                </Button>
              </div>
            </th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {currentRows.map(salesId => (
            <React.Fragment key={salesId.strSalesIdNbr}>
              <tr onClick={() => handleSalesIdRowClick(salesId)}>
                <td></td>
                <td>{salesId.strSalesIdNbr}</td>
                <td>{salesId.strSalesIdName}</td>
                <td>
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Detail</Tooltip>}>
                    <Button
                      onClick={() => { setSalesIdToShow(salesId); setShowDetailModal(true) }}
                      variant="white"
                      style={{ padding: '2px 4px' }}
                    >
                      <InfoCircle color="blue" />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Edit</Tooltip>}>
                    <Button
                      onClick={() => { setSalesIdToEdit(salesId); setShowEditModal(true); }}
                      variant="white"
                      style={{ padding: '2px 4px' }}
                    >
                      <Wrench color="black" />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Delete</Tooltip>}>
                    <Button
                      onClick={() => { setSalesIdToDelete(salesId); setShowDeleteModal(true); }}
                      variant="white"
                      style={{ padding: '2px 4px' }}
                    >
                      <Trash color="red" />
                    </Button>
                  </OverlayTrigger>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-between align-items-center my-3 mb-5" style={{ flexWrap: 'wrap' }}>
        <div>
          Showing {indexOfFirstRow + 1} to {Math.min(indexOfLastRow, totalRows)} of {totalRows} rows
        </div>
        <div className="d-flex align-items-center">
          <span className="me-2">Rows per page:</span>
          <Form.Select value={rowsPerPage} onChange={handleRowsPerPageChange} style={{ width: 'auto' }}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </Form.Select>
        </div>
        <Pagination className="mb-0">
          {renderPaginationItems()}
        </Pagination>
      </div>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this sales Id?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <Button variant="danger" onClick={() => handleSalesIdDeleted(salesIdToDelete)}>Delete</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Edit Sales Id</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SalesIdForm
            salesId={salesIdToEdit}
            onSalesIdUpdated={(updatedSalesId) => {
              handleSalesIdUpdated(updatedSalesId);
              setShowEditModal(false);
            }}
          />
        </Modal.Body>
      </Modal>

      <Modal show={showDetailModal} onHide={() => setShowDetailModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Sales Id Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {salesIdToShow && (
            <div style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
              <p><strong>Sales Id Number:</strong> {salesIdToShow.strSalesIdNbr}</p>
              <p><strong>Sales Id Name:</strong> {salesIdToShow.strSalesIdName}</p>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showAddModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Sales Id</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SalesIdForm
            onSalesIdAdded={handleSalesIdAdded}
            onModalClose={handleCloseModal}
            registerCleanup={(cleanup) => (cleanupRef.current = cleanup)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SalesIdList;
